<template>
  <div class="box">
    <div class="box-header">
      <h4>{{ pageTitle }}</h4>
    </div>

    <el-form
      v-if="model"
      :model="model"
      ref="model"
      label-width="140px"
      label-suffix=":"
    >
      <el-form-item label="选择店铺">
        <el-select
          v-model="model.store"
          placeholder="请选择"
        >
          <el-option
            v-for="item in shops"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="数量区间/序号">
        <el-input
          type="number"
           :min="0"
          style="width:100px;"
          v-model="model.min"
          placeholder=""
        ></el-input>
        -
        <el-input
          type="number"
           :min="1"
          style="width:100px;"
          v-model="model.max"
          placeholder=""
        ></el-input>
        <code style="color:#909399;">(为防止等待超时，建议输入范围在50左右) </code>
      </el-form-item>

      <el-form-item label="桌号前缀">
        <el-input
          style="width:200px;"
          v-model="model.type_name_number"
        ></el-input>
      </el-form-item>

       <el-form-item label="图片宽度">
        <el-input
          type="number"
          :min="0"
          style="width:200px;"
          v-model="model.width"
        ></el-input>
        <code style="color:#909399;"> 默认300大小，低于300取默认</code>
      </el-form-item>

       <!-- <el-form-item label="颜色">
         R-
        <el-input
        type="number"
          style="width:100px;"
          v-model="model.line_color.r"
        ></el-input>
        G-
         <el-input
         type="number"
          style="width:100px;"
          v-model="model.line_color.g"
        ></el-input>
        B-
         <el-input
         type="number"
          style="width:100px;"
          v-model="model.line_color.b"
        ></el-input>
        <code style="color:#909399;"> 颜色色值为RGB，数值大小0-255 </code>

      </el-form-item> -->

      <el-form-item>
        <el-button
          type="primary"
          size="medium"
          @click="onSubmit"
           :loading="submitLoading"
        >一键生成</el-button>
      </el-form-item>
    </el-form>

  </div>
</template>

<script>
import flatry from '@admin/utils/flatry'
import NumberService from '@admin/services/NumberService'
export default {
  name: '',
  data () {
    return {
      submitLoading: false,
      pageTitle: '创建桌号',
      model: null,
      shops: []
    }
  },

  async mounted () {
    const { data } = await flatry(NumberService.shops())
    if (data) {
      this.shops = data.data
      this.model = { line_color: {} }
    }
  },

  methods: {
    async onSubmit () {
      this.submitLoading = true

      const { data } = await flatry(NumberService.create(this.model))

      if (data) {
        this.$message.success(data.msg)

        this.$router.replace({ path: '/number/' })
      }
      this.submitLoading = false
    }
  }
}
</script>
<style lang='' scoped>
</style>
